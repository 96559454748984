<template>
  <div class="menu" :class="{ backchange2: isAbout }">
    <div class="menu-container">
      <div class="menu-logo">
        <router-link :to="{ name: 'langding' }" class="navbar-brand" v-if="isAbout">
          <img src="../assets/images/logo.png" alt="Logo" class="style-logo style-logo--scroll"
        /></router-link>
        <a class="navbar-brand" href="#" v-else>
          <img src="../assets/images/logo.png" alt="Logo" class="style-logo style-logo--scroll" />
        </a>
      </div>
      <div class="menu-mobile">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
      <ul class="sack-item">
        <div class="icon-close">
          <img src="../assets/images/x.svg" alt="" />
        </div>
        <li class="menu-item" v-if="!isCheckClick">
          <router-link :to="{ name: 'langding' }" class="menu-link">Home <i class="sack-menu-effect"></i></router-link>
        </li>
        <li class="menu-item li-item" v-if="isCheckClick">
          <a class="menu-link" @click="changeActive(0, 'whyblockchain')" :class="tabActive === 0 ? 'active' : null"
            >Why Blockchain?
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item li-item" v-if="isCheckClick">
          <a class="menu-link" @click="changeActive(1, 'services')" :class="tabActive === 1 ? 'active' : null"
            >Services
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item li-item" v-if="isCheckClick">
          <a class="menu-link" @click="changeActive(2, 'product')" :class="tabActive === 2 ? 'active' : null"
            >Products
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item li-item" v-if="isCheckClick">
          <a class="menu-link" @click="changeActive(3, 'partner')" :class="tabActive === 3 ? 'active' : null"
            >Customers
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item li-item">
          <a class="menu-link" @click="changeActive(4, 'teammate')" :class="tabActive === 4 ? 'active' : null"
            >Team
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item li-item">
          <a class="menu-link" @click="changeActive(5, 'contact')" :class="tabActive === 5 ? 'active' : null"
            >Contact
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item network">
          <div class="social_list">
            <div class="social_iem">
              <div>
                <img src="../assets/images/facebook.svg" alt="" />
              </div>
            </div>
            <div class="social_iem">
              <div>
                <img src="../assets/images/linkin.svg" alt="" />
              </div>
            </div>
            <div class="social_iem">
              <div>
                <img src="../assets/images/youtube.svg" alt="" />
              </div>
            </div>
          </div>
        </li>
        <li class="menu-item lang1">
          <div class="language-select-wraper">
            <el-select v-model="selectLanguage" @change="handleChangeLanguage" placeholder="Choose a language" class="select-language" popper-class="select-language-dropdown">
              <el-option :label="`${$t('language.en')}`" value="en">
                <template>
                  <div class="be-flex flag">
                    <img src="../assets/images/us-flag.svg" alt="" size="14" style="margin-right: 6px; width: 19px; height: 19px; margin-top: 1px" />
                    <span class="lang">{{ $t('language.en') }}</span>
                  </div>
                </template>
              </el-option>
              <el-option :label="`${$t('language.vi')}`" value="vi">
                <template>
                  <div class="be-flex flag">
                    <img
                      src="../assets/images/vn-flag.svg"
                      alt=""
                      size="19"
                      style="width: 22px; height: 22px; margin-right: 4px; margin-left: -2px; filter: brightness(1.1) contrast(1.3)"
                    />
                    <span class="langs" style="">{{ $t('language.vi') }}</span>
                  </div>
                </template>
              </el-option>
              <div class="suffix" slot="prefix">
                <img src="../assets/images/us-flag.svg" style="margin-right: 8px; margin-left: 0px; margin-top: 10px" v-if="getIcon === 'us-flag'" alt="" />
                <img
                  src="../assets/images/vn-flag.svg"
                  style="margin-right: 8px; margin-left: 0px; width: 24px; height: 23px; margin-top: 8px; filter: brightness(1.1) contrast(1.3)"
                  v-else
                  class="vn-flag"
                  alt=""
                />
              </div>
            </el-select>
          </div>
        </li>
        <li class="menu-item nav__mobile-item lang2">
          <div class="language2 language-select-wraper">
            <el-select v-model="selectLanguage" @click="handleChangeLanguage" placeholder="Choose a language" class="select-language" popper-class="select-language-dropdown">
              <el-option :label="`${$t('language.en-mobile')}`" value="en">
                <template>
                  <div class="be-flex flag" style="justify-content: unset">
                    <img src="../assets/images/us-flag.svg" alt="" size="14" style="margin-right: 6px; width: 19px; height: 19px; margin-top: 1px" />
                    <span class="lang">{{ $t('language.en-mobile') }}</span>
                  </div>
                </template>
              </el-option>
              <el-option :label="`${$t('language.vi-mobile')}`" value="vi">
                <template>
                  <div class="be-flex flag" style="justify-content: unset">
                    <img src="../assets/images/vn-flag.svg" alt="" size="19" style="width: 22px; height: 22px; margin-right: 4px; margin-left: -2px" />
                    <span class="langs" style="">{{ $t('language.vi-mobile') }}</span>
                  </div>
                </template>
              </el-option>
              <div class="suffix" slot="prefix">
                <img src="../assets/images/us-flag.svg" style="margin-right: 8px; margin-left: 0px; margin-top: 10px" v-if="getIcon === 'us-flag'" alt="" />
                <img src="../assets/images/vn-flag.svg" style="margin-right: 8px; margin-left: 0px; width: 24px; height: 23px; margin-top: 8px" v-else class="vn-flag" alt="" />
              </div>
            </el-select>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectLanguage: 'EN',
        isAbout: false,
        isCheckClick: true,
        tabActive: ''
      }
    },
    watch: {
      '$route.name': function (_new) {
        if (_new === 'About') {
          this.isAbout = true
          this.isCheckClick = false
        } else {
          this.isAbout = false
          this.isCheckClick = true
        }
      }
    },
    methods: {
      changeActive(item, id) {
        const sackItem = document.querySelector('.sack-item')
        const elm = document.getElementById(id)

        if (window.innerWidth < 1023) {
          sackItem.style.transform = 'translateX(100%)'
        }
        elm.scrollIntoView()
        if (item == 0) {
          this.tabActive = 0
        }
        if (item == 1) {
          this.tabActive = 1
        }
        if (item == 2) {
          this.tabActive = 2
        }
        if (item == 3) {
          this.tabActive = 3
        }
        if (item == 4) {
          this.tabActive = 4
        }
        if (item == 5) {
          this.tabActive = 5
        }
      },

      handleChangeLanguage(lang) {
        this.$i18n.locale = lang
        window.localStorage.setItem('bc-lang', lang)
      }
    },
    computed: {
      getIcon() {
        return this.selectLanguage === 'vi' ? 'vn-flag' : 'us-flag'
      }
    },
    created() {
      this.isAbout = this.$route.name === 'About'
    },
    mounted() {
      const lang = window.localStorage.getItem('bc-lang')
      const menuLink = document.querySelectorAll('.menu-link')
      console.log('menuLink', menuLink)
      const menuMobile = document.querySelector('.menu-mobile')
      const sackItem = document.querySelector('.sack-item')
      const iconClose = document.querySelector('.icon-close')
      if (!lang) {
        window.localStorage.setItem('bc-lang', 'vi')
      }
      this.selectLanguage = window.localStorage.getItem('bc-lang')

      // effect menu
      const menu = document.querySelector('.menu')
      window.addEventListener('scroll', function () {
        if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
          menu.classList.add('backchange')
        } else {
          menu.classList.remove('backchange')
          menuLink.forEach(x => {
            x.classList.remove('active')
          })
        }
      })
      // effect active
      // const liElement = document.querySelectorAll(".li-item");

      // menuLink.forEach((element) => {
      //   element.addEventListener("click", function () {
      //     if (window.innerWidth < 1023) {
      //       sackItem.style.transform = "translateX(100%)";
      //     }
      //     menuLink.forEach((aLink) => {
      //       aLink.classList.remove("active");
      //     });
      //     element.classList.add("active");
      //   });
      // });

      menuMobile.addEventListener('click', function () {
        sackItem.style.transform = 'translateX(0%)'
      })
      iconClose.addEventListener('click', function () {
        sackItem.style.transform = 'translateX(100%)'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    padding-top: 28px;
    transition: 0.3s linear;
    .menu-logo img {
      height: 64px;
    }
    .menu-container {
      max-width: 1340px;
      padding: 0 20px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sack-item {
      display: flex;
      align-content: center;
      list-style: none;
      .menu-item {
        padding: 0 28px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .menu-item:last-child {
        padding-right: 0;
      }
      .menu-link {
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;
        position: relative;
        padding-bottom: 15px;
        margin-top: 15px;
        color: #ececec;
      }
    }
    .menu-item:hover .menu-link {
      color: #fff;
    }
  }
  .backchange,
  .backchange2 {
    background: #fafafa;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding-top: 0;
  }
  .backchange {
    .menu-logo img {
      height: 64px;
    }
  }
  .backchange,
  .backchange2 {
    .sack-item .menu-item .menu-link {
      padding-bottom: 19px;
      margin-top: 19px;
      color: #363636 !important;
    }
    .sack-menu-effect {
      background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
    }
    .menu-item:hover .menu-link {
      background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .menu-link.active {
    background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600 !important;
  }
  .active .sack-menu-effect {
    width: 100%;
  }
  .be-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .language-select-wraper {
    width: 93px;
    select-language {
      input {
        background: none;
        border: none;
      }
      .suffix {
        .us-flag {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 7px;
          display: block;
        }
        .vn-flag {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 6px;
          display: block;
        }
      }
    }
  }
  .el-input__suffix {
    right: 10px;
    background: transparent;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 50px;
  }

  .language-select-wraper .el-input--prefix .el-input__inner {
    padding-left: 70px;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: transparent;
    color: #fff;
    width: 256px;
  }
  .el-select .el-input .el-select__caret {
    color: #c0c4cc;
    font-size: 26px !important;
    transition: transform 0.3s;
    transform: rotateZ(180deg);
    cursor: pointer;
    margin-right: 43px !important;
    margin-top: -9px !important;
  }
  .el-select .el-input .el-select__caret.is-reverse {
    transform: rotateZ(0);
    margin-top: 10px !important;
  }
  .sack-menu-effect {
    position: absolute;
    width: 0%;
    height: 2px;
    z-index: 1;
    background: white;
    bottom: -2px;
    left: 0;
    transition: width ease 0.3s;
    display: inline-block;
  }

  .select-language-dropdown {
    .el-select-dropdown__item {
      font-weight: 400;
    }
  }
  .menu-item :hover .sack-menu-effect {
    width: 100%;
  }
  .menu-mobile {
    display: none;
  }
  .bar1 {
    width: 15px;
    height: 2px;
    transform: translateX(9px);
  }
  .bar2 {
    width: 24px;
    height: 2px;
  }
  .bar3 {
    width: 15px;
    height: 2px;
    transform: translateX(9px);
  }
  .backchange,
  .backchange2 {
    .bar1,
    .bar2,
    .bar3 {
      background: #363636;
      border-radius: 99px;
      margin: 6px 0;
      transition: 0.4s;
    }
  }
  .bar1,
  .bar2,
  .bar3 {
    background: #fff;
    border-radius: 99px;
    margin: 6px 0;
    transition: 0.4s;
  }
  .icon-close {
    display: none;
  }
  .menu-item.network {
    display: none !important;
  }
  .menu-item.lang1 {
    padding-right: 0 !important;
  }
  .menu-item.lang2 {
    display: none !important;
  }

  @media screen and (max-width: 1023px) {
    .menu-mobile {
      display: block;
    }
    .menu-item.network {
      display: flex !important;
    }
    .menu-item.lang1 {
      display: none !important;
    }
    .menu-item.lang2 {
      display: flex !important;
    }
    .network {
      margin-top: 61px;
      margin-bottom: 48px;
      @media screen and (max-height: 650px) {
        margin-top: -4px;
        margin-bottom: 20px;
      }
    }
    .icon-close {
      text-align: right;
      display: block;
      margin-right: 24px;
      margin-top: 20px;
    }
    .menu {
      padding-top: 4px;
      .sack-item {
        flex-direction: column;
        position: fixed;
        top: 0px;
        background: #fafafa;
        padding-top: -40px;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(100%);
        transition: 0.5s ease;
        .menu-item {
          .menu-link {
            font-weight: 700;
            color: #363636;
            font-size: 24px;
            line-height: 28px;
            margin-top: 25px;
            padding-right: 58px;
            @media screen and (max-height: 650px) {
              margin-top: 20px;
            }
          }
        }
      }
      .menu-logo img {
        height: 48px;
      }
      .sack-menu-effect {
        display: none !important;
      }
    }
    .social_list {
      display: flex;
      justify-content: start;
      padding-top: 2.5rem;

      .social_iem {
        margin: -5px 6px;
        position: static;
        width: 44px;
        height: 42px;
        left: 0px;
        top: 0px;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-top: 2.5px;
        }
      }
      .social_iem:first-child {
        margin-left: 0;
      }
    }
    .language2 .select-language {
      min-width: 147px !important;
    }
  }
</style>
