import firebase from 'firebase'
import 'firebase/database'
import 'firebase/firestore'

const secondaryAppConfig = {
  apiKey: 'AIzaSyCTfuPfk_ByM5TdzDFqBW8HqENmHttAV2Y',
  authDomain: 'beedu-website.firebaseapp.com',
  projectId: 'beedu-website',
  storageBucket: 'beedu-website.appspot.com',
  messagingSenderId: '271787299808',
  appId: '1:271787299808:web:db32f3208999aadfcf9185',
  measurementId: 'G-RCFB0S9J5T'
}
const defaultProject = firebase.initializeApp(secondaryAppConfig)

export default defaultProject
