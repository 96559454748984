import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'langding',
    component: () => import(/* webpackChunkName:"langding" */ '../views/Introduce')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName:"about" */ '../views/About.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  setTimeout(() => {
    next()
  }, 0)
})

export default router
